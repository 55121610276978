import axios from 'axios'
import authHeader from './auth-header'
import store from "../stores/store";
import { log } from '../helpers/logger';

class request {

    static getService(url, data) {
        store.commit("set_loading", true);
        url = data ? url + '?' + new URLSearchParams(data).toString() : url;
        return new Promise((resolve, reject) => {
            axios.get(url, { headers: authHeader() })
            .then(res => {
                return resolve(res);
            }).catch((error) => {
                log('services.requests.getService() - ' + error.message, 'error');
                return reject(error);
            }).finally(() => {
                store.commit("set_loading", false);
            });
        });
    }

    static postService(url, data) {
        store.commit("set_loading", true);
        return new Promise((resolve, reject) => {
            axios.post(url, data, { headers: authHeader() })
            .then(res => {
                return resolve(res)
            }).catch((error) => {
                log('services.requests.postService() - ' + error.message, 'error');
                return reject(error);
            }).finally(() => {
                store.commit("set_loading", false);
            });
        });
    }

    static postAzureService(url, data, accessToken) {
        store.commit("set_loading", true);
        const bearer = `Bearer ${accessToken}`;
        var header =  { 'Authorization': bearer }
        return new Promise((resolve, reject) => {
            axios.post(url, data, { headers: header })
            .then(res => {
                return resolve(res)
            }).catch((error) => {
                log('services.requests.postService() - ' + error.message, 'error');
                return reject(error);
            }).finally(() => {
                store.commit("set_loading", false);
            });
        });
    }

    static putService(url, data) {
        store.commit("set_loading", true);
        return new Promise((resolve, reject) => {
            axios.put(url, data, { headers: authHeader() })
            .then(res => {
                return resolve(res)
            }).catch((error) => {
                log('services.requests.putService() - ' + error.message, 'error');
                return reject(error);
            }).finally(() => {
                store.commit("set_loading", false);
            });
        });
    }

    static deleteService(url, data) {
        store.commit("set_loading", true);
        return new Promise((resolve, reject) => {
            axios.delete(url, { headers: authHeader(), data })
            .then(res => {
                return resolve(res)
            }).catch((error) => {
                log('services.requests.deleteService() - ' + error.message, 'error');
                return reject(error);
            }).finally(() => {
                store.commit("set_loading", false);
            });
        });
    }
}

export default request;
