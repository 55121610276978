import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "../stores/store";
import i18n from "../i18n";

const OrderToken = () => import("../pages/OrderToken");
const Checkout = () => import("../pages/OrderToken/Checkout");
const Confirmation = () => import("../pages/OrderToken/Confirmation");
const IdentificationPage = () => import("../pages/OrderToken/Identification");
const FinishedPage = () => import("../pages/OrderToken/FinishedPage");
const Signature = () => import("../pages/OrderToken/Signature");
const Seal = () => import("../pages/OrderToken/Seal");
const Pickup = () => import("../pages/PickupPage/index");
const PickupLogin = () => import("../pages/PickupPage/Login");
const ScriptDownload = () => import("../pages/PickupPage/ScriptDownload");
const Admin = () => import("../pages/AdminPage/index");
const Licenses = () => import("../pages/AdminPage/Licenses");
const Users = () => import("../pages/AdminPage/Users");
const Whitelabeling = () => import("../pages/AdminPage/Whitelabeling");
const EMail = () => import("../pages/AdminPage/Email");
const Webhook = () => import("../pages/AdminPage/Webhook");
const SealRequest = () => import("../pages/AdminPage/SealRequest");
const IdentAgencyRequest = () => import("../pages/AdminPage/IdentAgencyRequest");
const NewWorkflows = () => import("../pages/NewWorkflows");
const CustomWorkflow = () => import("../pages/NewWorkflows/CustomWorkflow");
const TemplateWorkflow = () => import("../pages/NewWorkflows/TemplateWorkflow");
const MyWorkflows = () => import("../pages/MyWorkflows");
const CreatorWorkflows = () => import("../pages/MyWorkflows/CreatorWorkflows");
const MineWorkflows = () => import("../pages/MyWorkflows/MyWorkflows");
const TeamWorkflows = () => import("../pages/MyWorkflows/TeamWorkflows");
const Login = () => import("../pages/LoginPage");
const RequestDemo = () => import("../pages/RequestDemo");
const Register = () => import("../pages/RegisterPage");
const RegisterUser = () => import("../pages/RegisterPage/RegisterUser.vue");
const RegisterOrganization = () => import("../pages/RegisterPage/RegisterOrganization.vue");
const RegisterRouting = () => import("../pages/RegisterPage/RegisterRouting.vue");
const Home = () => import("../pages/HomePage");
const Quickn8 = () => import("@/components/ui/Quickn8");
const Contacts = () => import("../pages/ContactsPage");
const ContactsTable = () => import("../pages/ContactsPage/ContactsTable");
const AddContactsDialog = () => import("@/components/ui/AddContactDialog");
const ContactsDialog = () => import("@/components/ui/ContactsDialog");
const MultipleContactsDialog = () => import("../pages/ContactsPage/MultipleContactsDialog");
const EmailVerification = () => import("../pages/EmailVerificationPage");
const Forgot = () => import("../pages/ForgotPage");
const Reset = () => import("../pages/ResetPage");
const Account = () => import("../pages/ProfilePage");
const Teams = () => import("../pages/TeamsPage");
const TeamsTable = () => import("../pages/TeamsPage/TeamsTable");
const TeamsDialog = () => import("@/components/ui/TeamsDialog");
const AddTeamsDialog = () => import("@/components/ui/AddTeamDialog");
const SealTeamsTable = () => import("../pages/TeamsPage/SealTeamsTable");
const Templates = () => import("../pages/TemplateCreatorPage");
const TemplateTable = () => import("../pages/TemplateCreatorPage/TemplateTable");
const TemplatePreview = () => import("../pages/TemplateCreatorPage/TemplatePreview");
const EditTemplate = () => import("../pages/TemplateCreatorPage/EditTemplate");
const AddTemplate = () => import("../pages/TemplateCreatorPage/AddTemplate");
const SigningArea = () => import("../pages/SigningAreaPage");
const Download = () => import("../pages/DownloadPage");
const Redirect = () => import("../pages/RedirectPage");
const Verify = () => import("../pages/VerifyPage");
const NewOrg = () => import("../pages/NewOrgPage");

import {
  reset,
  logged,
  notlogged,
  isAdmin,
  isActiveAdmin,
  hasFeatureTemplates,
  hasFeatureTeams,
  hasSubscriptionFeatures,
  isLicenseActive,
  checkAuthenticatedUser,
} from "./middleware";

Vue.use(VueRouter);

axios.defaults.baseURL = window.location.origin + "/api/";
axios.defaults.withCredentials = true;

const routes = [
  {
    path: "/",
    redirect: `/home`,
    meta: {
      middleware: [logged],
    },
  },
  {
    path: "/orderToken/:order_id",
    component: OrderToken,
    name: "OrderToken",
    props: true,
  },
  {
    path: "/tokenCheckout",
    component: Checkout,
    name: "Checkout",
  },
  {
    path: "/tokenConfirmation",
    component: Confirmation,
    props: true,
    name: "Confirmation",
  },
  {
    path: "/requestIdent/:request_id",
    component: IdentificationPage,
    name: "Identification",
    props: true,
  },
  {
    path: "/finishedToken/:request_id?",
    component: FinishedPage,
    name: "FinishedPage",
  },
  {
    path: "/seal/:order_id",
    component: Seal,
    name: "OrderTokenSeal",
    props: true,
  },
  {
    path: "/signature/:order_id",
    component: Signature,
    name: "OrderTokenSignature",
    props: true,
  },
  {
    path: "/pickupCertificate/:pickup_id",
    name: "Pickup",
    redirect: "/pickupCertificate/:pickup_id/login",
    component: Pickup,
    props: true,
    children: [
      {
        path: "login",
        props: true,
        component: PickupLogin,
      },
      {
        path: "download",
        props: true,
        component: ScriptDownload,
        meta: {
          middleware: [checkAuthenticatedUser],
        },
      },
    ],
  },
  {
    path: "/admin",
    redirect: "/admin/licenses",
    name: "Admin",
    component: Admin,
    props: true,
    meta: {
      middleware: [logged, isAdmin],
    },
    children: [
      {
        path: "licenses",
        component: Licenses,
        meta: {
          middleware: [logged, isAdmin],
        },
      },
      {
        path: "users",
        component: Users,
        meta: {
          middleware: [logged, isAdmin],
        },
        children: [
          {
            path: ":user_id",
            props: true,
          },
        ],
      },
      {
        path: "whitelabeling",
        component: Whitelabeling,
        meta: {
          middleware: [logged, isAdmin],
        },
      },
      {
        path: "email",
        component: EMail,
        meta: {
          middleware: [logged, isAdmin],
        },
      },
      {
        path: "webhook",
        component: Webhook,
        meta: {
          middleware: [logged, isAdmin],
        },
      },
      {
        path: "sealRequest",
        component: SealRequest,
        meta: {
          middleware: [logged, isActiveAdmin],
        },
      },
      {
        path: "identAgencyRequest",
        component: IdentAgencyRequest,
        meta: {
          middleware: [logged, isActiveAdmin],
        },
      },
    ],
  },
  {
    path: "/workflow",
    redirect: "/workflow/custom",
    name: "NewWorkflows",
    component: NewWorkflows,
    props: true,
    meta: {
      middleware: [logged, isLicenseActive, hasSubscriptionFeatures],
    },
    children: [
      {
        path: "custom",
        component: CustomWorkflow,
        meta: {
          middleware: [logged, isLicenseActive, hasSubscriptionFeatures],
        },
      },
      {
        path: "template",
        component: TemplateWorkflow,
        meta: {
          middleware: [logged, isLicenseActive, hasSubscriptionFeatures],
        },
      },
    ],
  },
  {
    path: "/myWorkflows",
    name: "MyWorkflows",
    redirect: "/myWorkflows/created",
    component: MyWorkflows,
    props: true,
    meta: {
      middleware: [logged],
    },
    children: [
      {
        path: "created",
        component: CreatorWorkflows,
        meta: {
          middleware: [logged],
        },
      },
      {
        path: "teams",
        component: TeamWorkflows,
        meta: {
          middleware: [logged],
        },
      },
      {
        path: "mine",
        component: MineWorkflows,
        meta: {
          middleware: [logged],
        },
      },
    ],
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      middleware: [logged],
    },
  },
  {
    path: "/quicksign",
    name: "Quickn8",
    component: Quickn8,
    meta: {
      middleware: [logged],
    },
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    meta: {
      middleware: [logged],
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    redirect: "/contacts/contactbook",
    component: Contacts,
    props: true,
    meta: {
      middleware: [logged],
    },
    children: [
      {
        path: "contactbook",
        props: true,
        component: ContactsTable,
        meta: {
          middleware: [logged],
        },
      },
      {
        path: "new",
        props: true,
        component: AddContactsDialog,
        meta: {
          middleware: [logged],
        },
      },
      {
        path: "multiple",
        props: true,
        component: MultipleContactsDialog,
        meta: {
          middleware: [logged],
        },
      },
      {
        path: "edit/:contact_id",
        props: true,
        component: ContactsDialog,
        meta: {
          middleware: [logged],
        },
      },
    ],
  },
  {
    path: "/templates",
    redirect: "/templates/list",
    name: "Templates",
    component: Templates,
    props: true,
    meta: {
      middleware: [logged, isLicenseActive, hasFeatureTemplates],
    },
    children: [
      {
        path: "list",
        props: true,
        component: TemplateTable,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTemplates],
        },
      },
      {
        path: "preview/:template_id",
        props: true,
        component: TemplatePreview,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTemplates],
        },
      },
      {
        path: "edit/:template_id",
        props: true,
        component: EditTemplate,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTemplates],
        },
      },
      {
        path: "new",
        props: true,
        component: AddTemplate,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTemplates],
        },
      },
    ],
  },
  {
    path: "/teams",
    name: "Teams",
    redirect: "/teams/list",
    props: true,
    component: Teams,
    meta: {
      middleware: [logged, isLicenseActive, hasFeatureTeams],
    },
    children: [
      {
        path: "list",
        props: true,
        component: TeamsTable,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTeams],
        },
      },
      {
        path: "new",
        props: true,
        component: AddTeamsDialog,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTeams],
        },
      },
      {
        path: "seals",
        props: true,
        component: SealTeamsTable,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTeams],
        },
      },
      {
        path: "edit/:team_id",
        props: true,
        component: TeamsDialog,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTeams],
        },
      },
      {
        path: "details/:team_id",
        props: true,
        component: TeamsDialog,
        meta: {
          middleware: [logged, isLicenseActive, hasFeatureTeams],
        },
      },
    ],
  },
  {
    path: "/signingArea/:memberID/:organization_id",
    name: "SigningArea",
    component: SigningArea,
    props: true,
  },
  {
    path: "/downloadDocument/:organization_id/:workflow_id",
    name: "Download",
    component: Download,
    props: true,
  },
  {
    path: "/login/:redirected?",
    component: Login,
    name: "login",
    meta: {
      middleware: [notlogged],
    },
  },
  {
    path: "/requestDemo",
    component: RequestDemo,
    name: "requestDemo",
    meta: {
      middleware: [notlogged],
    },
  },
  {
    path: "/register",
    component: Register,
    name: "register",
    meta: {
      middleware: [notlogged],
    },
    children: [
      {
        path: "registerRouting",
        props: true,
        component: RegisterRouting,
      },
      {
        path: "registerUser",
        props: true,
        component: RegisterUser,
      },
      {
        path: "registerOrganization",
        props: true,
        component: RegisterOrganization,
      },
    ],
  },
  {
    path: "/emailVerification/:user_id",
    component: EmailVerification,
    name: "email-verification",
    props: true,
    meta: {
      middleware: [notlogged],
    },
  },
  {
    path: "/verify/:user_id/:user_otp",
    component: Verify,
    name: "verify",
    props: true,
    meta: {
      middleware: [notlogged],
    },
  },
  {
    path: "/redirect*",
    component: Redirect,
    name: "redirect",
    meta: {
      middleware: [notlogged],
    },
  },

  {
    path: "/newOrganization",
    component: NewOrg,
    name: "new_organization",
    meta: {
      middleware: [notlogged],
    },
  },
  {
    path: "/forgot",
    component: Forgot,
    name: "forgot",
    meta: {
      middleware: [notlogged],
    },
  },
  {
    path: "/reset",
    component: Reset,
    name: "reset",
    meta: {
      middleware: [reset, notlogged],
    },
  },
  {
    path: "*",
    redirect: "/home",
  },
];

axios.interceptors.response.use(
  (res) => {
    store.commit("ui/set_notification", { display: false });

    if (res.data.code) {
      store.commit("ui/set_notification", {
        display: true,
        code: res.data.code,
        alertClass: "success",
      });
    }
    return res;
  },
  (err) => {
    const {
      response: { status, data },
    } = err;
    store.commit("ui/set_notification", { display: false });
    if (data.code) {
      if (data.data) {
        store.commit("ui/set_notification", {
          display: true,
          code: data.data[0].msg,
          alertClass: "red",
        });
      } else {
        store.commit("ui/set_notification", {
          display: true,
          code: data.code,
          alertClass: "red",
        });
      }
    }

    if (router.currentRoute.path.startsWith("/verify/")) {
      if (status === 401) {
        router.push("/");
        return Promise.reject(err);
      }
    }

    if (status === 401) {
      store.dispatch("auth/logout").then(() => {
        router.push("/");
      });
      router.push("/");
      return Promise.reject(err);
    }
    if (status === 405) {
      router.push("/");
      return Promise.reject(err);
    }

    store.commit("set_loading", false);
    return Promise.reject(err);
  }
);

const router = new VueRouter({
  mode: "history",
  routes,
  base: "/",
  duplicateNavigationPolicy: "ignore",
});

router.beforeEach((to, from, next) => {
  store.commit("set_loading", true);
  if (localStorage.getItem("unsaved_data") == "true" && Object.keys(to.params).length === 0) {
    if (confirm(i18n.t("Headers.unsaved_data_alert"))) {
      localStorage.setItem("unsaved_data", false);
      return next();
    } else {
      store.commit("set_loading", false);
      return;
    }
  }

  if (to.meta && to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});

router.afterEach((to, from, next) => {
  store.commit("set_loading", false);
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

export default router;
