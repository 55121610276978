import i18n from "../../i18n";
import requests from "../../services/requests";
import store from "../store";

export const request = {
  namespaced: true,
  state: {
    accessToken: "",
    userSecret: "",
    reference: "",
  },
  getters: {
    accessToken: (state) => {
      return state.accessToken;
    },
    userSecret: (state) => {
      return state.userSecret;
    },
  },
  actions: {
    getRequestWithIdentification: async ({ commit }, id) => {
      store.commit("set_loading", true);
      return requests
        .getService("request/ident/" + id)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    getOrder: async ({ commit }, id) => {
      store.commit("set_loading", true);
      return requests
        .getService("request/getOrder/" + id)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    getOrderContent: async ({ commit }, id) => {
      store.commit("set_loading", true);
      return requests
        .getService("request/getOrderContent/" + id)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    getWorkflowLink: async ({ commit }, data) => {
      store.commit("set_loading", true);
      return requests
        .getService("request/getWorkflowLink/" + data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    sendMailOTP: async ({ commit }, data) => {
      store.commit("set_loading", true);
      return requests
        .postService("request/sendMailOTP", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    checkOTP: async ({ commit }, data) => {
      store.commit("set_loading", true);
      return requests
        .postService("request/checkOTP", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    getOrderContent: async ({ commit }, id) => {
      store.commit("set_loading", true);
      return requests
        .getService("request/getOrderContent/" + id)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    sendTokenRecipientMail: async ({ commit }, data) => {
      store.commit("set_loading", true);
      return requests
        .postService("request/sendTokenRecipientMail", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    orderTokenSignature: async ({ commit }, data) => {
      store.commit("set_loading", true);
      return requests
        .postService("request/orderTokenSignature", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    orderTokenSignaturePersonalIdent: async ({ commit }, data) => {
      store.commit("set_loading", true);
      return requests
        .postService("request/orderTokenSignaturePersonalIdent", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    orderTokenSeal: async ({ commit }, data) => {
      store.commit("set_loading", true);
      return requests
        .postService("request/orderTokenSeal", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    checkIdentification: async ({ commit }, data) => {
      store.commit("set_loading", true);
      return requests
        .postService("request/checkIdentification", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
    login: async ({ commit }, user) => {
      store.commit("set_loading", true);
      return requests
        .postService("request/login", user)
        .then(
          async (resp) => {
            let user = resp.data.data.user;
            commit("loginSuccess", user);
            return Promise.resolve(resp.data.data.url);
          },
          (error) => {
            commit("loginFailure");
            return Promise.reject(error);
          }
        )
        .finally(() => {
          store.commit("set_loading", false);
        });
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.accessToken = user.userAccessToken;
      state.userSecret = user.userSecret;
      state.reference = user.reference;
    },
    loginFailure(state) {
      state.accessToken = "";
      state.userSecret = "";
      state.reference = "";
    },
  },
};
