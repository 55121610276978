import requests from "../../services/requests";

const user = JSON.parse(localStorage.getItem("user"));
const language = JSON.parse(localStorage.getItem("language"));
const initialState = user
  ? { status: { loggedIn: true }, user, language }
  : {
    status: { loggedIn: false },
    user: null,
    language,
  };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login: ({ commit }, user) => {
      return requests
        .postService("auth/login", user)
        .then((resp) => {
          let user = resp.data.data;
          if (user.accessToken) {
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("language", JSON.stringify(user.language_id));
            commit("set_language", user.language_id);
          }
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
          (error) => {
            commit("loginFailure");
            return Promise.reject(error);
          }
        );
    },
    loginMS: ({ commit }, data) => {
      return requests
        .postAzureService("auth/loginMS", data.data, data.bearer)
        .then((resp) => {
          let user = resp.data.data.user;
          if (user && user.accessToken) {
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem(
              "language",
              JSON.stringify(user.language_id)
            );
            commit("set_language", user.language_id);
            commit("loginSuccess", user);
          }
          return Promise.resolve(resp.data.data);
        },
          (error) => {
            commit("loginFailure");
            return Promise.reject(error);
          }
        )
    },
    loginForCheckout: async ({ commit }, user) => {
      return requests
        .postService("auth/loginForCheckout", user)
        .then(
          async (resp) => {
            let externalBillingID = resp.data.data;
            return Promise.resolve(externalBillingID);
          },
          (error) => {
            commit("loginFailure");
            return Promise.reject(error);
          }
        );
    },
    resendVerificationEmail({ commit }, user) {
      return requests
        .postService("stripe/resendVerificationEmail", user)
        .then((resp) => {
          return Promise.resolve(resp);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    },
    verify({ commit }, user) {
      return requests
        .getService(
          "auth/verify?user_id=" + user.user_id + "&otp=" + user.otp + "&s=1"
        )
        .then((resp) => {
          return Promise.resolve(resp);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    },
    logout({ commit }) {
      return requests.deleteService("auth/logout").then(
        () => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          commit("logout");
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    register({ commit }, user) {
      return requests.postService("auth/" + user.api, user).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createUserRegistrationLink({ commit }, license_key) {
      return requests.postService("auth/createUserRegistrationLink", license_key).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    registerTempOrg({ commit }, user) {
      return requests.postService("auth/registerTempOrg", user).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateTempOrg({ commit }, user) {
      return requests.postService("auth/updateTempOrg", user).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createStripeCustomer({ commit }, user) {
      return requests.postService("auth/createCustomer", user).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    forgot({ commit }, email) {
      return requests
        .postService("auth/forgotPassword", { email })
        .then(
          (resp) => {
            return Promise.resolve(resp);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    reset({ commit }, payload) {
      let queryString = new URLSearchParams(payload.query).toString();
      return requests
        .postService("auth/resetPassword?" + queryString, payload.user)
        .then(
          (resp) => {
            return Promise.resolve(resp);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    check_user({ commit }, user) {
      return requests
        .postService("auth/check_user", user)
        .then(
          (resp) => {
            return Promise.resolve(resp);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    updateMyUser({ commit }, user) {
      return requests
        .postService("auth/updateMyUser", user)
        .then(
          (resp) => {
            localStorage.setItem("user", JSON.stringify(resp.data.data));
            commit("set_user", resp.data.data);
            return Promise.resolve(resp);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    updateMyPassword({ commit }, user) {
      return requests
        .postService("auth/updateMyPassword", user)
        .then(
          (resp) => {
            return Promise.resolve(resp);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getMyUser() {
      return requests
        .getService("auth/getMyUser")
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
  },
  getters: {
    loggedIn: (state) => {
      return state.status.loggedIn;
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    set_language(state, locale) {
      state.language = locale;
    },
    set_user(state, user) {
      state.user = user;
    },
  },
};
