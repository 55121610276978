import requests from "../../services/requests";

export const data = {
  namespaced: true,
  state: {
    contacts: [],
    documents: null,
    teams: null,
    seal_teams: null,
    users: null,
    members: null,
    templates: null,
    licenses: null,
    organization: null,
    organizations: null,
    workflow: null,
    quickn8: null,
  },
  actions: {
    startQuickn8: async ({ commit }, data) => {
      return requests.postService("workflow/startQuickn8", data).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getSealStatus: async ({ commit }, data) => {
      return requests.postService("user/getSealStatus", data).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getContacts: async ({ commit }) => {
      return requests.getService("contact").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getUserContacts: async ({ commit }) => {
      return requests.getService("contact/user").then(
        (resp) => {
          commit("set_contacts", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getContact: async ({ commit }, id) => {
      return requests.getService("contact/id/" + id).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editContact: async ({ commit }, contact) => {
      return requests.putService("contact/" + contact.contact_id, contact).then(
        (resp) => {
          commit("edit_contact", resp.data.data[0]);
          return Promise.resolve(resp.data.data[0]);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addContact: async ({ commit }, contact) => {
      return requests.postService("contact", contact).then(
        (resp) => {
          commit("add_contact", resp.data.data[0]);
          return Promise.resolve(resp.data.data[0]);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteContact: async ({ commit }, id) => {
      return requests.deleteService("contact/" + id).then(
        (resp) => {
          commit("delete_contacts", [id]);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteContacts: async ({ commit }, ids) => {
      return requests.deleteService("contact/delete", { ids }).then(
        (resp) => {
          commit("delete_contacts", ids);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addContactsMultiple: async ({ commit }, data) => {
      return requests.postService("contact/multiple", data).then(
        (resp) => {
          // commit('add_contact', resp.data.data)
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    testContactsMultiple: async ({ commit }, data) => {
      return requests.postService("contact/multiple/test", data).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    ////////////////// Actions for documents /////////
    getWorkflows: async ({ commit }, filter) => {
      return requests.getService("workflow", filter).then(
        (resp) => {
          commit("set_documents", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getFiles: async ({ commit }, data) => {
      return requests
        .getService(
          "documents/" + data.organization_id + "/" + data.workflow_id
        )
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getDocument: async ({ commit }, id) => {
      return requests.getService("workflow/" + id).then(
        (resp) => {
          commit("set_workflow", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getDocumentDecrypted: async ({ commit }, id) => {
      return requests.getService("workflow/decrypted/" + id).then(
        (resp) => {
          commit("set_workflow", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteWorkflow: async ({ commit }, id) => {
      return requests.deleteService("workflow/" + id).then(
        (resp) => {
          commit("delete_documents", [id]);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    downloadPDF: async ({ commit }, id) => {
      return requests.getService("workflow/" + id + "/download").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    signNow: async ({ commit }, data) => {
      return requests
        .postService("workflow/" + data.workflow_id + "/sign", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    convertFileToPdf: async ({ commit }, data) => {
      return requests.postService("documents/convertFileToPdf", data).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    sendReminder: async ({ commit }, id) => {
      return requests.getService("workflow/" + id + "/reminder").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    sendSupportMail: async ({ commit }, mail) => {
      return requests.postService("wiki/sendSupportMail", mail).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    sendBugMail: async ({ commit }, mail) => {
      return requests.postService("wiki/sendBugMail", mail).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getVersion: async () => {
      return requests.getService("wiki/getVersion").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    sendDocumentsBulk: async ({ commit }, data) => {
      return requests.postService("workflow/bulk", data).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    sendCustomDocument: async ({ commit }, document) => {
      return requests.postService("workflow/custom", document).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    testBulk: async ({ commit }, data) => {
      return requests.postService("workflow/bulk/test", data).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    ////////////////// Actions for Users /////////
    getUser: async ({ commit }, id) => {
      return requests.getService("user/" + id).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getUsers: async ({ commit }) => {
      return requests.getService("user").then(
        (resp) => {
          commit("set_users", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getUserTeams: async ({ commit }, id) => {
      return requests.getService("user/" + id + "/teams").then(
        (resp) => {
          commit("set_teams", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editUser: async ({ commit }, user) => {
      return requests.putService("user/editUser/" + user.user_id, user).then(
        (resp) => {
          commit("edit_user", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteUser: async ({ commit }, id) => {
      return requests.deleteService("user/" + id).then(
        (resp) => {
          commit("delete_user", [id]);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deactivateUser: async ({ commit }, user) => {
      return requests.putService("user/deactivateUser", user).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    reactivateUser: async ({ commit }, user) => {
      return requests.putService("user/reactivateUser", user).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    ////////////////// Actions for Teams /////////
    updateTeamSettings: async ({ commit }, data) => {
      return requests
        .putService("team/" + data.team_id + "/settings", {
          name: data.name,
          description: data.description,
        })
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    updateTeamMembers: async ({ commit }, data) => {
      return requests
        .putService("team/" + data.team_id, { members: data.members })
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    addTeamMembers: async ({ commit }, data) => {
      return requests
        .postService("team/" + data.team_id, { members: data.members })
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getTeamMembers: async ({ commit }, id) => {
      return requests.getService("team/" + id + "/members").then(
        (resp) => {
          commit("set_members", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getTeams: async ({ commit }) => {
      return requests.getService("team/getTeams").then(
        (resp) => {
          commit("set_teams", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getSealTeams: async ({ commit }) => {
      return requests.getService("team/getSealTeams").then(
        (resp) => {
          commit("set_seal_teams", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getTeamSettings: async ({ commit }, id) => {
      return requests.getService("team/" + id + "/settings").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteTeam: async ({ commit }, id) => {
      return requests.deleteService("team/" + id).then(
        (resp) => {
          commit("delete_team", [id]);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addTeam: async ({ commit }, team) => {
      return requests.postService("team", team).then(
        (resp) => {
          commit("add_team", resp.data.data[0]);
          return Promise.resolve(resp.data.data[0]);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    /////////////// Actions for templates //////////
    getTemplates: async ({ commit }) => {
      return requests.getService("template").then(
        (resp) => {
          commit("set_templates", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getTemplate: async ({ commit }, id) => {
      return requests.getService("template/" + id).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteTemplate: async ({ commit }, id) => {
      return requests.deleteService("template/" + id).then(
        (resp) => {
          commit("delete_templates", [id]);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editTemplate: async ({ commit }, template) => {
      return requests
        .putService("template/" + template.template_id, template)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getTemplateView: async ({ commit }, id) => {
      return requests.getService("template/" + id + "/documents").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getTemplateFields: async ({ commit }, id) => {
      return requests.getService("template/" + id + "/fields").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getIdentAgencyState: async ({ commit }, id) => {
      return requests.getService("ident-agency/getIdentAgencyState").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addTemplate: async ({ commit }, template) => {
      return requests.postService("template", template).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addSealRequest: async ({ commit }, data) => {
      return requests.postService("seal/addSealRequest", data).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addIdentAgencyRequest: async ({ commit }, data) => {
      return requests
        .postService("ident-agency/addIdentAgencyRequest", data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getCertificateStatus: async ({ commit }) => {
      return requests.getService("organization/certificates").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    /////////////// Actions for licenses //////////
    getOrgLicenses: async ({ commit }) => {
      return requests.getService("organization/licenses").then(
        (resp) => {
          commit("set_licenses", resp.data.data);
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    /////////////// Actions for webhook //////////
    getWebhook: async ({ commit }, url) => {
      return requests.getService("organization/webhook").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateWebhook: async ({ commit }, url) => {
      return requests.postService("organization/webhook", url).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    /////////////// Actions for Stripe ///////////////
    getStripeCustomerID: async ({ commit }, id) => {
      return requests.getService("stripe/getStripeCustomerID/" + id).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getSessionData: async ({ commit }, sessionID) => {
      return requests.getService("auth/getSessionData/" + sessionID).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateSessionData: async ({ commit }, sessionID, sessionData) => {
      return requests
        .postService("auth/updateSessionData/", sessionID, sessionData)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getUserEmailByOrderReference: async ({ commit }, order_reference) => {
      return requests
        .getService("stripe/getUserEmailByOrderReference/" + order_reference)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getUserEmail: async ({ commit }, user_id) => {
      return requests.getService("auth/getUserEmail/" + user_id).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getTempUser: async ({ commit }, organizationID) => {
      return requests.getService("auth/getTempUser/" + organizationID).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getTempLicenseKeyID: async ({ commit }, organizationID) => {
      return requests
        .getService("auth/getTempLicenseKeyID/" + organizationID)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    createBillingSession: async ({ commit }, sessionData) => {
      return requests.postService("stripe/createBillingSession/", sessionData).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createCheckoutSession({ commit }, checkoutData) {
      return requests
        .postService("stripe/createCheckoutSession/", checkoutData)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    updateUserIdentAgentState({ commit }, data) {
      return requests
        .postService("user/updateUserIdentAgentState/" + data.user_id, data)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    createTrialCheckoutSession({ commit }, checkoutData) {
      return requests
        .postService("stripe/createTrialCheckoutSession/", checkoutData)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getStripePrice({ commit }, checkoutData) {
      return requests.postService("stripe/getStripePrice", checkoutData).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createCustomCheckoutSession({ commit }, checkoutData) {
      return requests
        .postService("stripe/createCustomCheckoutSession/", checkoutData)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    createTokenCheckoutSession({ commit }, checkoutData) {
      return requests
        .postService("stripe/createTokenCheckoutSession/", checkoutData)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    checkTrialPeriod({ commit }, organization) {
      return requests.postService("stripe/checkTrial", organization).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    endTrialPeriod({ commit }, payload) {
      return requests.postService("stripe/endTrialPeriod", payload).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    chargeSealForm({ commit }, organization) {
      return requests.postService("stripe/chargeSealForm", organization).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    /////////////// Actions for Organizations //////////
    editOrganization: async ({ commit }, org) => {
      return requests
        .putService("organization/" + org.organization_id, org)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    addOrganization: async ({ commit }, org) => {
      return requests.postService("organization", org).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateLicenseDescription: async ({ commit }, body) => {
      return requests
        .postService( "organization/editLicenseDescription/" , body)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    editOrganizationLicense: async ({ commit }, lic) => {
      return requests
        .putService("organization/" + lic.license_key_id + "/license", lic)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    addOrganizationAssociate: async ({ commit }, associate) => {
      return requests
        .postService(
          "organization/" + associate.organization_id + "/associate",
          associate
        )
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    editOrganizationAssociate: async ({ commit }, associate) => {
      return requests
        .putService(
          "organization/" + associate.associate_id + "/associate",
          associate
        )
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    addOrganizationAddress: async ({ commit }, address) => {
      return requests
        .postService(
          "organization/" + address.organization_id + "/address",
          address
        )
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    editOrganizationAddress: async ({ commit }, address) => {
      return requests
        .putService("organization/" + address.address_id + "/address", address)
        .then(
          (resp) => {
            return Promise.resolve(resp.data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        );
    },
    getRoles: async () => {
      return requests.getService("general/roles").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    /////////////// Actions for Interfaces //////////
    getInterface: async ({ commit }, name) => {
      return requests.getService("ui/" + name).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getInterfaceColor: async ({ commit }, id) => {
      return requests.getService("workflow/getInterface/" + id).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getInterfaceColorDecrypted: async ({ commit }, id) => {
      return requests.getService("workflow/getInterfaceDecrypted/" + id).then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getInterfaces: async () => {
      return requests.getService("ui/").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    putInterface: async ({ commit }, data) => {
      return requests.putService("ui/" + data.interface_name, data).then(
        (resp) => {
          var user = resp.data.data;
          if (user.accessToken) {
            localStorage.setItem("user", JSON.stringify(user));
          }
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    putInterfaceWeb: async ({ commit }, data) => {
      return requests.putService("ui/web/" + data.id, data).then(
        (resp) => {
          var user = resp.data.data;
          if (user.accessToken) {
            localStorage.setItem("user", JSON.stringify(user));
          }
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteInterface: async ({ commit }, name) => {
      return requests.deleteService("ui/" + name).then(
        (resp) => {
          var user = resp.data.data;
          if (user.accessToken) {
            localStorage.setItem("user", JSON.stringify(user));
          }
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteInterfaceWeb: async ({ commit }, data) => {
      return requests.deleteService("ui/web/" + data.id).then(
        (resp) => {
          var user = resp.data.data;
          if (user.accessToken) {
            localStorage.setItem("user", JSON.stringify(user));
          }
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteInterfaceMail: async ({ commit }, data) => {
      return requests.deleteService("ui/mail/" + data.id).then(
        (resp) => {
          var user = resp.data.data;
          if (user.accessToken) {
            localStorage.setItem("user", JSON.stringify(user));
          }
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    testMail: async () => {
      return requests.postService("ui/testMail").then(
        (resp) => {
          return Promise.resolve(resp.data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    set_contacts(state, data) {
      state.contacts = data;
    },
    add_contact(state, data) {
      state.contacts.push(data);
    },
    edit_contact(state, data) {
      let contacts = state.contacts.filter(
        (con) => con.contact_id !== data.contact_id
      );
      contacts.push(data);
      state.contacts = contacts;
    },
    delete_contacts(state, data) {
      state.contacts = state.contacts.filter(
        (contact) => !data.includes(contact.contact_id)
      );
    },
    //////////////// mutations for documents
    set_documents(state, data) {
      state.documents = data;
    },
    delete_documents(state, data) {
      state.documents = state.documents.filter(
        (document) => !data.includes(document.workflow_id)
      );
    },
    set_workflow(state, data) {
      state.workflow = data;
    },
    set_quickn8(state, data) {
      state.quickn8 = data;
    },
    //////////////// mutations for users
    set_users(state, data) {
      state.users = data;
    },
    edit_user(state, data) {
      let users = state.users.filter((us) => us.user_id !== data.user_id);
      users.push(data);
      state.users = users;
    },
    delete_user(state, data) {
      state.users = state.users.filter((user) => !data.includes(user.user_id));
    },
    //////////////// mutations for teams
    set_teams(state, data) {
      state.teams = data;
    },
    set_seal_teams(state, data) {
      state.seal_teams = data;
    },
    set_members(state, data) {
      state.members = data;
    },
    delete_team(state, data) {
      state.teams = state.teams.filter((team) => !data.includes(team.team_id));
    },
    add_team(state, data) {
      state.teams.push(data);
    },
    //////////////// mutations for templates
    set_templates(state, data) {
      state.templates = data;
    },
    delete_templates(state, data) {
      state.templates = state.templates.filter(
        (temp) => !data.includes(temp.template_id)
      );
    },
    //////////////// mutations for licenses
    set_licenses(state, data) {
      state.licenses = data;
    },
    //////////////// mutations for organizations
    set_organizations(state, data) {
      state.organizations = data;
    },
    set_organization(state, data) {
      state.organization = data;
    },
  },
};
