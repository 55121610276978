import Vue from "vue";
import store from "./stores/store";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

// Just needed for font sizes
import "bootstrap/dist/css/bootstrap.css";
//export default of language
import i18n from "./i18n";

import titleMixin from "./mixins/titleMixin";
import JsonEditor from "vue-json-edit";

Vue.use(JsonEditor);

Vue.mixin(titleMixin);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
