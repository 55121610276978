function log(message, level) {
    if (level === 'error') {
        console.error('SIGN8 - ERROR - ' + message);
    } else if (level === 'warn') {
        console.warn('SIGN8 - WARN - ' + message);
    } else {
        console.log('SIGN8 - INFO - ' + message);
    }
}

exports.log = log;