<template>
  <v-container class="ma-0 pa-0 mw-100">
    <app-navbar />
    <app-bar />
    <div v-if="currentUser">
      <v-system-bar v-if="currentUser.license_expired" window color="warning" style="">
        <v-spacer />
        <div style="color: white; text-align: center">
          <b style="font-size: medium">{{ this.$t("Responses.INFO_BAR_EXPIRED_LICENSE") }}</b>
        </div>
        <v-spacer />
      </v-system-bar>
    </div>
    <app-notification />
    <v-main class="pt-lg-9">
      <v-container class="px-5 px-md-13 px-sm-0 px-lg-16 pb-md-8 pb-lg-10 pb-sm-0 mw-100">
        <router-view></router-view>
      </v-container>
    </v-main>
    <cookie-banner
      theme="base"
      buttonClass="cookieButton primary"
      :buttonText="$t('LoginPage.cookieButton')"
      :message="$t('LoginPage.cookieText')"
      storageType="cookies"
      storageName="consentCookie"
      :cookieOptions="{ expires: 7 }"
    >
    </cookie-banner>
  </v-container>
</template>

<script>
import AppBar from "./AppBar";
import AppNotification from "./AppNotification";
import AppNavbar from "./AppNavbar";
import CookieBanner from "./CookieBanner";

export default {
  name: "app-layout",
  components: {
    AppNavbar,
    AppBar,
    AppNotification,
    CookieBanner,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    $route(to, from) {
      if (from.fullPath === "/admin/whitelabeling") {
        this.$vuetify.theme.themes.light.primary = localStorage.getItem("color");
        if (this.currentUser) {
          this.currentUser.whitelabeling_pack.website_logo.logo = localStorage.getItem("logo");
        }
      }
    },
  },
  created() {
    this.$vuetify.theme.themes.light.primary = this.currentUser
      ? this.currentUser.whitelabeling_pack.style.mainColor
      : "#1E6569";
    this.$vuetify.theme.themes.light.secondary = this.currentUser
      ? this.currentUser.whitelabeling_pack.style.mainColor
      : "#1E6569";
    this.$vuetify.theme.themes.light.accent = this.currentUser
      ? this.currentUser.whitelabeling_pack.style.mainColor
      : "#1E6569";
    localStorage.setItem("color", this.$vuetify.theme.themes.light.primary);
    this.currentUser
      ? localStorage.setItem("logo", this.currentUser.whitelabeling_pack.website_logo.logo)
      : "../../assets/img/logo_w.svg";
  },
};
</script>

<style>
:root {
  --white: #fff;
  --black: #000000;
  --turquoise: #1e6668;
}

.Cookie--bottom {
  background-color: var(--white);
}

.Cookie--bottom .cookieButton {
  background-color: var(--turquoise);
  box-shadow: none;
  outline: none;
  border: none;
  color: var(--white);
  padding: 0.75rem 1.25rem;
}
</style>
