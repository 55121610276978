function getTitle(vm) {
    const {title} = vm.$options
    if (title) {
        return typeof title === 'function'
            ? title.call(vm)
            : title
    }
}

/**
 * @vue-event {} created() - copies the pdfexpress stuff to the public folder
 */

export default {
    created() {
        const title = getTitle(this)
        if (title) {
            document.title = title + " - SIGN8"
        }
    }
}