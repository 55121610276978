<template>
  <v-app app style="background-color: #f0f0f0">
    <!--<v-system-bar window color="warning">
      <v-spacer />

      <div style="color: white; text-align: center;"><b>{{ $t('info_bar_maintanance') }}</b></div>

      <v-spacer />
    </v-system-bar>-->
    <app-layout />
  </v-app>
</template>

<script>
localStorage.setItem("unsaved_data", false)
import AppLayout from './components/layout/AppLayout'

export default {
  name: 'App',
  components: {
    AppLayout
  }
};
</script>
