<template>
  <v-navigation-drawer v-model="drawer" app hide-overlay :permanent="toggleNavbar" v-if="currentUser" fill-height>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item :style="styleObject()" to="/home" v-bind="attrs" v-on="on">
          <v-img :src="currentUser
            ? currentUser.whitelabeling_pack.website_logo.logo
            : require('../../assets/img/logo_w_small.svg')
            " min-height="25px" :max-width="mini ? '' : '100px'"></v-img>
        </v-list-item>
      </template>
      <span>{{ $t("Navigation.home") }}</span>
    </v-tooltip>

    <v-list>
      <v-list-item v-if="currentUser.trial">
        <v-chip color="orange" label class="w-100 ">
          {{ $t("Navigation.TrialChip") }}
        </v-chip>
      </v-list-item>
      <v-list-item :disabled="currentUser.license_expired || !currentUser.subscription_features" :class="{ 'greyed-out': currentUser.license_expired || !currentUser.subscription_features }" link
        to="/workflow" class="notUnderlined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-text-box-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t("Navigation.newDoc")
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t("Navigation.newDoc") }}</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item link to="/myWorkflows" class="notUnderlined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-folder-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t("Navigation.documents")
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t("Navigation.documents") }}</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item :disabled="currentUser.license_expired || !currentUser.subscription_features" :class="{ 'greyed-out': currentUser.license_expired || !currentUser.subscription_features}"
        v-if="currentUser.features.templates" link to="/Templates" class="notUnderlined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-text-box-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t("Navigation.templates")
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t("Navigation.templates") }}</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item link to="/contacts" class="notUnderlined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-contacts-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t("Navigation.contacts")
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t("Navigation.contacts") }}</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item :disabled="currentUser.license_expired || !currentUser.subscription_features" :class="{ 'greyed-out': currentUser.license_expired || !currentUser.subscription_features }"
        v-if="currentUser.features.teams" link to="/Teams" class="notUnderlined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-account-supervisor-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t("Navigation.teams")
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t("Navigation.teams") }}</span>
        </v-tooltip>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list-item v-if="currentUser.roles.Admin" link to="/admin" class="notUnderlined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t("Navigation.settings")
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t("Navigation.settings") }}</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item link to="/account" class="notUnderlined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-emoticon-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t("Navigation.account")
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t('Navigation.account') }}</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item link target="_blank" href="https://sign8.eu/documentation/" class="notUnderlined">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-help-circle-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t("Navigation.help")
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t("Navigation.help") }}</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item link to="/Logout" class="notUnderlined" @click="logOut" data-cy="navbarLogoutBtn">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title class="notUnderlined primary--text">{{
                $t('Navigation.logout')
              }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ $t('Navigation.logout') }}</span>
        </v-tooltip>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "app-navbar",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentLanguage() {
      return this.$store.state.auth.language || "de";
    },
    mini: function () {
      return this.state;
    },
  },
  created() {
    this.mobile = this.$vuetify.breakpoint.mdAndDown;
    this.$store.state.toggleNavbar = true;
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        this.state = true;
        break;
      case 'sm':
        this.state = true;
        break;
      case 'md':
        this.state = true;
        break;
      case 'lg':
        this.state = false;
        break;
      case 'xl':
        this.state = false;
        break;
      default:
        this.state = true;
        break;
    }
  },
  data() {
    return {
      drawer: null,
      mobile: false,
      toggleNavbar: false,
      state: false
    };
  },
  methods: {
    styleObject() {
      var style = {}
      if (this.mini) {
        style = {
          height: `115px !important`,
          'padding': '30%',
          "background-color": `${this.$vuetify.theme.themes.light.primary}`
        }
      } else {
        style = {
          height: `115px !important`,
          'max-height': `115px !important`,
          'padding-left': '30%',
          "background-color": `${this.$vuetify.theme.themes.light.primary}`,
        }
      }
      return style;
    },
    logOut() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push('/login')
        })
    },
    to(link) {
      this.$router.push(link)
    },
    openInNewTab(route) {
      window.open(route, '_blank')
    },
    getIcon() {
      if (this.mini) {
        this.$store.state.toggleNavbar = false;
        return "mdi-chevron-double-right";
      } else {
        this.$store.state.toggleNavbar = true;
        return "mdi-chevron-double-left";
      }
    },
  },
  mounted() {
    this.$root.$on("toggleNavbarMobile", () => {
      {
        this.toggleNavbar
          ? (this.drawer = false)
          : (this.drawer = true);
      }
    });
  },
};
</script>
<style scoped>
.notUnderlined {
  text-decoration: none;
  color: inherit;
}

.greyed-out {
  opacity: 0.5;
  pointer-events: none;
}
</style>