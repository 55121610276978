<template>
  <v-snackbar :color="notification.class" v-model="snackbar" multi-line top :timeout="30000"
              data-cy="snackbarNotification">
    <!--<v-icon>notification_important</v-icon>-->
    {{ notification.text }}
    <v-btn text dark @click.prevent="snackbar = false" data-cy="snackbarClose">
      <v-icon>mdi-close-thick</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>

import {
  mapGetters
} from "vuex";

/**
 * @vue-computed {} get() - returns the notifications from the vuex store
 * @vue-computed {String} set(value) - sets a new notification in the vuex store
 */

export default {
  name: "app-notification",
  computed: {
    ...mapGetters({
      notification: "ui/notification"
    }),
    snackbar: {
      get() {
        return this.$store.getters["ui/notification"].display;
      },
      set() {
        this.$store.commit("ui/set_notification", {
          display: false
        })
      }
    }
  }
};
</script>
