import store from "../stores/store";
import { log } from "@/helpers/logger";

export async function reset({ next, to }) {
  store
    .dispatch("auth/check_user", to.query)
    .then(() => {
      return next();
    })
    .catch((err) => {
      log("router.middleware.reset() - " + err, "error");
      return next("/login");
    });
}

export function logged({ next }) {
  const loggedIn = localStorage.getItem("user");
  if (loggedIn) {
    return next();
  } else {
    return next("/login");
  }
}

export function notlogged({ next }) {
  const loggedIn = localStorage.getItem("user");
  if (loggedIn) {
    return next("/home");
  } else {
    return next();
  }
}

export function isActiveAdmin({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.roles.Admin || user.license_expired) {
    return next("/home");
  } else {
    return next();
  }
}

export function isAdmin({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.roles.Admin) {
    return next("/home");
  } else {
    return next();
  }
}

export function isDeveloper({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.roles.Developer) {
    return next("/home");
  } else {
    return next();
  }
}

export function isSuperuser({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.roles.SuperUser) {
    return next("/home");
  } else {
    return next();
  }
}

export function hasFeatureTeams({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.features.teams) {
    return next("/home");
  } else {
    return next();
  }
}

export function hasFeatureTemplates({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.features.templates) {
    return next("/home");
  } else {
    return next();
  }
}

export function hasFeatureBulk({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.features.bulk_workflow) {
    return next("/home");
  } else {
    return next();
  }
}

export function hasSubscriptionFeatures({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.subscription_features) {
    return next("/home");
  } else {
    return next();
  }
}

export function hasFeatureWhitelabeling({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user.features.whitelabeling) {
    return next("/home");
  } else {
    return next();
  }
}

export function isLicenseActive({ next }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user.license_expired) {
    return next("/home");
  } else {
    return next();
  }
}

export function checkAuthenticatedUser({ next }) {
  let token = store.state.request.accessToken;
  let secret = store.state.request.userSecret;
  if (token && secret) {
    return next();
  } else {
    return next("/");
  }
}
