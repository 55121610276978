<template>
  <div>
    <!-- <v-app-bar app color="transparent" flat dense hide-on-scroll prominent v-if="currentUser && !mobile" style="left:95%">
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-container class="ma-0 pa-0 mw-100" fill-height>
          <v-layout align-center>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar size="34" :color="mainColor" v-bind="attrs" v-on="on" data-cy="profileIcon">
                  <span style="color: white; font-size: 12px">{{
                    currentUser.first_name[0].toUpperCase() + currentUser.last_name[0].toUpperCase() || '?'
                  }}</span>
                </v-avatar>
              </template>

              <v-list justify="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="to('/account')" @click.middle="openInNewTab('/account')" v-bind="attrs"
                      v-on="on">
                      <v-icon :color="mainColor">mdi-emoticon-outline</v-icon>
                    </v-list-item>
                  </template>
                  <span>{{ $t('Navigation.account') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="logOut" @click.middle="openInNewTab('/login')" v-bind="attrs" v-on="on" data-cy="logoutBtn">
                      <v-icon :color="mainColor">mdi-logout</v-icon>
                    </v-list-item>
                  </template>
                  <span>{{ $t('Navigation.logout') }}</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </v-layout>
        </v-container>
      </v-toolbar-items>
    </v-app-bar> -->
    <v-app-bar app :color="mainColor" flat dense style="position:absolute" v-if="currentUser && mobile">
      <v-btn icon class="d-flex" @click="openNavbar">
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { log } from '@/helpers/logger'
import InfoBanner from './InfoBanner.vue'

/**
 * @vue-computed {} currentUser() - returns the current user
 * @vue-computed {} currentLanguage() - returns the current language
 * @vue-method {} logOut() - logs out the current user
 * @vue-method {String} to(link) - pushes router to the next location
 * @vue-method {String} openInNewTab(route) - opens new route in a new tab
 * @vue-method {String} setLocale(locale) - sets new language
 */

export default {
  name: 'app-bar',
  components: {
    InfoBanner,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    currentLanguage() {
      return this.$store.state.auth.language || 'de'
    },
  },
  watch: {
    currentUser() {
      this.mainColor = this.currentUser.whitelabeling_pack.style.mainColor
    },
  },
  created() {
    this.mainColor = localStorage.getItem('color');
    this.mobile = this.$vuetify.breakpoint.mdAndDown
  },
  data() {
    return {
      language_items: [
        {
          value: 'en',
          text: 'EN',
        },
        {
          value: 'de',
          text: 'DE',
        },
      ],
      mainColor: null,
      mobile: false,
    }
  },
  methods: {
    logOut() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push('/login')
        })
        .catch((err) => {
        })
    },
    to(link) {
      this.$router.push(link)
    },
    openInNewTab(route) {
      window.open(route, '_blank')
    },
    setLocale(locale) {
      localStorage.setItem('language', JSON.stringify(locale))
      this.$store.commit('auth/set_language', locale)
      this.$router.go()
    },
    openNavbar() {
      this.$root.$emit('toggleNavbarMobile')
    },
  },
}
</script>
<style scoped>
.v-menu__content {
  left: calc(100% - 60px) !important;
}
</style>