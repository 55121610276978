<template>
  <v-snackbar v-model="snackbar" color="red" multi-line top :timeout="-1">
    <!--<v-icon>notification_important</v-icon>-->
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'InfoBanner',
  props: {
    message: {
      type: String,
    },
  },
  data() {
    return {
      snackbar: true,
    }
  },
}
</script>
