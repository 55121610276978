import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import store from "./../stores/store";

import { Ripple } from 'vuetify/lib/directives'

import '@mdi/font/css/materialdesignicons.css'

import de from 'vuetify/src/locale/de.ts'
import en from 'vuetify/src/locale/en.ts'


Vue.use(Vuetify, {
    directives: {
        Ripple
    }
})

Vue.component('my-component', {
    methods: {
        changeLocale() {
            if (store.state.auth.user) {
                this.$vuetify.lang.current = store.state.auth.user.language_id;
            } else {
                this.$vuetify.lang.current = 'de';
            }
        },
    },
})

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            dark: false,
            light: {
                primary: '#1e6668',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
            }
        },
    },
    lang: {
        locales: { de, en },
        current: store.state.auth.user ? store.state.auth.user.language_id : 'de'
    }
});